/* General Navbar Styles */
.navbar {
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #e0e0e0;
  transition: background-color 0.3s ease;
}

html {
  scroll-behavior: smooth;
}

/* Logo */
.navbar img {
  width: 160px;
  height: auto;
  margin-left: 10rem;
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.5);
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space-x-4 > :not(:last-child) {
  margin-right: 1rem;
}

/* Navigation Links */
.nav-link {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  transition: color 0.3s, transform 0.3s;
  position: relative;
}

.nav-link:hover {
  color: #0E74A4;
  transform: translateY(-2px); /* Lift effect on hover */
}


/* Products & Services Dropdown */
.products-services-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 235px;
  background-color: #1B3855;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.3s ease;
  transform: translateY(10px); /* Dropdown starts slightly below */
}

.group:hover .products-services-dropdown,
.relative:hover .products-services-dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Dropdown moves into place */
}

/* Category Styling */
.category {
  position: relative;
}

.dropdown-link {
  display: block;
  padding: 0.75rem 1.25rem;
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, padding-left 0.2s;
}

.dropdown-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
  padding-left: 1.5rem;
}

/* Sub-dropdown styling */
.sub-dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 130px;
  background-color: #1B3855;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.3s ease;
  transform: translateX(10px); /* Sub-dropdown appears from the right */
}

.category:hover .sub-dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateX(0); /* Sub-dropdown slides into place */
}

.sub-dropdown-link {
  display: block;
  padding: 0.75rem 1.25rem;
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, padding-left 0.2s;
}

.sub-dropdown-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
  padding-left: 1.5rem;
}

/* About Us Dropdown */
.aboutdropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 180px;
  background-color: #1B3855;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.3s ease;
  transform: translateY(10px);
}

.relative:hover .aboutdropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.aboutdropdown-link {
  display: block;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  transition: background-color 0.3s ease, padding-left 0.2s;
}

.aboutdropdown-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
  padding-left: 1.5rem;
}
/* General Navbar Styles for Mobile */
@media (max-width: 1023px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom: 2px solid #e0e0e0;
  }

  /* Logo aligned to the left */
  .navbar img {
    width: 120px;
    height: auto;
    margin-left: 0;
  }

  /* Hamburger menu aligned to the right */
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    right: 20px; /* Aligns the hamburger menu to the right corner */
    top: 3.65rem;
  }
  
  .hamburger span {
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar .flex-col {
    flex-direction: column;
    align-items: center;
  }
  

  /* Navigation menu hidden initially */
  ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    z-index: 200;
    padding: 1rem 0;
  }

  /* Show the navbar when hamburger is clicked */
  ul.active {
    display: flex;
  }

  ul li {
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .nav-link {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    text-decoration: none;
    padding: 0.75rem;
    display: block;
  }

  .nav-link:hover {
    color: #0E74A4;
  }
}

/* Hamburger menu toggle functionality */
.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}